const SUB_PATHS = {
    GENERAL: 'general',
    BLOG: 'blog',
    TECHNOLOGIES: 'technologies',
    SERVICES: 'services',
    CASE_STUDIES: 'case-studies',
    EXPERTISES: 'expertises',
    RESOURCES: 'resources',
};
const META_PATHS = {
    TITLE: 'head-siteTitles',
    DESCRIPTION: 'head-descriptions',
    KEYWORDS: 'keywords',
    OG_URL: 'head-ogUrl',
};
const OG_TYPES = {
    WEBSITE: 'website',
};
const OG_IMAGES_ROOT_PATH = '/og-images';
const imagePath = (path) => `${OG_IMAGES_ROOT_PATH}/${path}`;
const DEFAULT_IMAGE_PATH = imagePath(`${SUB_PATHS.GENERAL}/default-image.png`);

const pageTagsConstructor = (tags) => {
    const result = { ...tags };
    for (const [key, value] of Object.entries(tags)) {
        result[key] = {
            title: `${META_PATHS.TITLE}.${value.path}`,
            ogTitle: `${META_PATHS.TITLE}.${value.path}`,
            description: `${META_PATHS.DESCRIPTION}.${value.path}`,
            ogDescription: `${META_PATHS.DESCRIPTION}.${value.path}`,
            keywords: `${META_PATHS.KEYWORDS}.${value.path}`,
            ogUrl: `${META_PATHS.OG_URL}.${value.path}`,
            ogType: OG_TYPES.WEBSITE,
            ogImage: result[key].ogImage,
        };
    }
    return result;
};

const ROOT_SINGLE_ROUTE_PAGES = {
    HOME: {
        path: 'index',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    PAGE_NOT_FOUND: {
        path: 'page-not-found',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    BACKLINKS: {
        path: 'backlinks',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    FUNDS_EU: {
        path: 'eu-funds',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    PRIVACY_POLICY: {
        path: 'privacy-policy',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    TERMS_OF_SERVICE: {
        path: 'terms-of-service',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    RN_SELECT_PRO_DOCS: {
        path: 'rn-select-pro',
        ogImage: DEFAULT_IMAGE_PATH,
    },
    SOFTWARE_DEVELOPMENT_PROCESS: {
        path: 'software-development-process',
        ogImage: imagePath(
            `${SUB_PATHS.GENERAL}/software-development-process.png`,
        ),
    },
    ESTIMATE_THE_PROJECT: {
        path: 'estimate-the-project',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/estimate-the-project.png`),
    },
    BLOG: {
        path: 'blog',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/blog.png`),
    },
    PRESS_RELEASE: {
        path: 'press-release',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/press-release.png`),
    },
    OUR_PARTNERS: {
        path: 'our-partners',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/our-partners.png`),
    },
    TEAM: {
        path: 'team',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/team.png`),
    },
    CAREERS: {
        path: 'careers',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/careers.png`),
    },
    CONTACT_US: {
        path: 'contact-us',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/contact-us.png`),
    },
};

const SERVICES_PAGES = {
    SERVICES: {
        path: SUB_PATHS.SERVICES,
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/index.png`),
    },
    DATA_SCIENCE: {
        path: 'data-science',
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/data-science.png`),
    },
    MOBILE_DEVELOPMENT: {
        path: 'mobile-development',
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/mobile-development.png`),
    },
    WEBFLOW_AGENCY: {
        path: 'webflow-agency',
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/webflow-agency.png`),
    },
    WEB_DEVELOPMENT: {
        path: 'web-development',
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/web-development.png`),
    },
    BUSINESS_INTELLIGENCE: {
        path: 'business-intelligence',
        ogImage: imagePath(`${SUB_PATHS.SERVICES}/business-intelligence.png`),
    },
};

const CASE_STUDIES_PAGES = {
    CASE_STUDIES: {
        path: SUB_PATHS.CASE_STUDIES,
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/index.png`),
    },
    CASE_STUDIES_NFT: {
        path: 'case-studies-nft',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/nft.png`),
    },
    CASE_STUDIES_DESA_LOCUM: {
        path: 'case-studies-desa-locum',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/desa-locum.png`),
    },
    CASE_STUDIES_TURO: {
        path: 'case-studies-turo',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/turo.png`),
    },
    CASE_STUDIES_CBRE: {
        path: 'case-studies-cbre',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/cbre.png`),
    },
    CASE_STUDIES_MUSIC_DISTRIBUTION: {
        path: 'case-studies-music',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/music.png`),
    },
    CASE_STUDIES_LETEMPICK: {
        path: 'case-studies-letempick',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/letempick.png`),
    },
    CASE_STUDIES_TIPSTERS: {
        path: 'case-studies-4tipsters',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/4tipsters.png`),
    },
    CASE_STUDIES_MONEY_TRANSFER_APPLICATION: {
        path: 'case-studies-money-transfer',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/money-transfer.png`),
    },
    CASE_STUDIES_AIRDANCE: {
        path: 'case-studies-airdance',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/airdance.png`),
    },
    CASE_STUDIES_NOMIS_SOLUTIONS: {
        path: 'case-studies-nomis',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/nomis.png`),
    },
    CASE_STUDIES_PREGNABIT: {
        path: 'case-studies-pregnabit',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/pregnabit.png`),
    },
    CASE_STUDIES_CRYPTOCURRENCY_EXCHANGE_PLATFORM: {
        path: 'case-studies-cryptocurrency-exchange-platform',
        ogImage: imagePath(
            `${SUB_PATHS.CASE_STUDIES}/cryptocurrency-exchange-platform.png`,
        ),
    },
    CASE_STUDIES_SALES_PREDICTIVE_ALGORITHM: {
        path: 'case-studies-sales-predictive-algorithm',
        ogImage: imagePath(
            `${SUB_PATHS.CASE_STUDIES}/sales-predictive-algorithm.png`,
        ),
    },
    CASE_STUDIES_AUDIO_MUSIC_MARKETPLACE: {
        path: 'case-studies-audio-music-marketplace',
        ogImage: imagePath(
            `${SUB_PATHS.CASE_STUDIES}/audio-music-marketplace.png`,
        ),
    },
    CASE_STUDIES_CHURN: {
        path: 'case-studies-churn',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/churn.png`),
    },
    CASE_STUDIES_URBAN_ONE: {
        path: 'case-studies-urban-one',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/urban-one.png`),
    },
    CASE_STUDIES_OBJEKTO: {
        path: 'case-studies-objekto',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/objekto.png`),
    },
    CASE_STUDIES_EXFLUENTIAL: {
        path: 'case-studies-exfluential',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/exfluential.png`),
    },
    CASE_STUDIES_HEYWAY: {
        path: 'case-studies-heyway',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/heyway.png`),
    },
    CASE_STUDIES_AWS: {
        path: 'case-studies-aws',
        ogImage: imagePath(`${SUB_PATHS.CASE_STUDIES}/aws.png`),
    },
};

const TECHNOLOGIES_PAGES = {
    TECHNOLOGIES: {
        path: SUB_PATHS.TECHNOLOGIES,
        ogImage: imagePath(`${SUB_PATHS.TECHNOLOGIES}/index.png`),
    },
    REACT_NATIVE: {
        path: 'react-native-development',
        ogImage: imagePath(
            `${SUB_PATHS.TECHNOLOGIES}/react-native-development.png`,
        ),
    },
    NODE_JS: {
        path: 'node-js-development',
        ogImage: imagePath(`${SUB_PATHS.TECHNOLOGIES}/node-js-development.png`),
    },
    REACT_JS: {
        path: 'react-js-development',
        ogImage: imagePath(
            `${SUB_PATHS.TECHNOLOGIES}/react-js-development.png`,
        ),
    },
    JAVA_SCRIPT: {
        path: 'java-script-development',
        ogImage: imagePath(
            `${SUB_PATHS.TECHNOLOGIES}/java-script-development.png`,
        ),
    },
    VUE_JS: {
        path: 'vue-js-development',
        ogImage: imagePath(`${SUB_PATHS.TECHNOLOGIES}/vue-js-development.png`),
    },
    CADENCE_FLOW: {
        path: 'flow-cadence-development',
        ogImage: imagePath(
            `${SUB_PATHS.TECHNOLOGIES}/flow-cadence-development.png`,
        ),
    },
    R: {
        path: 'r-development',
        ogImage: imagePath(`${SUB_PATHS.TECHNOLOGIES}/r-development.png`),
    },
    AWS: {
        path: 'aws-development',
        ogImage: imagePath(`${SUB_PATHS.TECHNOLOGIES}/aws-development.png`),
    },
};

const EXPERTISES_PAGES = {
    EXPERTISES: {
        path: 'expertises',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/expertises.png`),
    },
    PROP_TECH_REAL_ESTATE: {
        path: 'prop-tech-real-estate',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/prop-tech-real-estate.png`),
    },
    AUDIO_VIDEO: {
        path: 'audio-video',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/audio-video.png`),
    },
    LIFESTYLE: {
        path: 'lifestyle',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/lifestyle.png`),
    },
    FINTECH: {
        path: 'fintech',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/fintech.png`),
    },
    NFT: {
        path: 'nft',
        ogImage: imagePath(`${SUB_PATHS.EXPERTISES}/nft.png`),
    },
    TECHNICAL_DUE_DILIGENCE: {
        path: 'technical-due-diligence',
        ogImage: imagePath(`${SUB_PATHS.GENERAL}/technical-due-diligence.png`),
    },
};

const RESOURCES_PAGES = {
    RESOURCES: {
        path: SUB_PATHS.RESOURCES,
        ogImage: imagePath(`${SUB_PATHS.RESOURCES}/index.png`),
    },
    CROSS_PLATFORM_LANDING_PAGE: {
        path: 'cross-platform-landing-page',
        ogImage: imagePath(
            `${SUB_PATHS.RESOURCES}/cross-platform-landing-page.png`,
        ),
    },
    MEDICAL_DATA_PROTECTION_LANDING_PAGE: {
        path: 'medical-data-protection-landing-page',
        ogImage: imagePath(
            `${SUB_PATHS.RESOURCES}/medical-data-protection-landing-page.png`,
        ),
    },
};

const BLOG_PAGES = {
    BLOG_FINTECH: {
        path: 'blog-fintech',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/fintech.png`),
    },
    BLOG_PROPTECH: {
        path: 'blog-proptech',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/proptech.png`),
    },
    BLOG_BLOCKCHAIN: {
        path: 'blog-blockchain',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/blockchain.png`),
    },
    BLOG_JAVASCRIPT: {
        path: 'blog-javascript',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/javascript.png`),
    },
    BLOG_SOFTWARE_BUSINESS: {
        path: 'blog-software-business',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/software-business.png`),
    },
    BLOG_HR: {
        path: 'blog-hr',
        ogImage: imagePath(`${SUB_PATHS.BLOG}/hr.png`),
    },
};

export const TAGS = pageTagsConstructor({
    ...ROOT_SINGLE_ROUTE_PAGES,
    ...SERVICES_PAGES,
    ...CASE_STUDIES_PAGES,
    ...TECHNOLOGIES_PAGES,
    ...EXPERTISES_PAGES,
    ...RESOURCES_PAGES,
    ...BLOG_PAGES,
});
